<template>
    <div class="flex items-center justify-center h-screen bg-gray-900 text-white">
        <div class="text-center">
            <h1 class="text-4xl font-bold mb-4">#OnielPlaylistWrapped2025</h1>
            <p class="text-lg mb-6">Stay tuned.</p>
            <div class="flex justify-center space-x-4 text-2xl">
                <div class="flex flex-col items-center">
                    <span class="font-bold text-5xl">{{ days }}</span>
                    <span>Days</span>
                </div>
                <div class="flex flex-col items-center">
                    <span class="font-bold text-5xl">{{ hours }}</span>
                    <span>Hours</span>
                </div>
                <div class="flex flex-col items-center">
                    <span class="font-bold text-5xl">{{ minutes }}</span>
                    <span>Minutes</span>
                </div>
                <div class="flex flex-col items-center">
                    <span class="font-bold text-5xl">{{ seconds }}</span>
                    <span>Seconds</span>
                </div>
            </div>
            <!-- Audio Controls -->
            <div class="flex items-center justify-center space-x-4 p-5">
                <button @click="togglePlay" class="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded">
                    {{ isPlaying ? "Jeda Sejenak" : "Mainkan lagu 'Waiting Room'" }}
                </button>
                <button @click="toggleMute" class="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded">
                    {{ isMuted ? "Bunyikan" : "Bisukan" }}
                </button>
            </div>
        </div>

        <!-- Audio Element -->
        <audio ref="audio" :src="audioSrc" loop muted></audio>
    </div>
    <AppFooter />
</template>

<script>
import AppFooter from '@/components/shared/AppFooterNonHome.vue';

export default {
    name: 'Stay Tuned',
    components: {
        AppFooter,
    },
    data() {
        return {
            targetDate: new Date("2025-12-31T23:59:00+07:00").getTime(),
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,

            // Audio Properties
            audioSrc: require("@/assets/audio/waitingroom.mp3"), // Path file lokal
            isPlaying: false,
            isMuted: true,
        };
    },
    methods: {
        updateCountdown() {
            const now = new Date().getTime();
            const distance = this.targetDate - now;

            if (distance < 0) {
                this.days = this.hours = this.minutes = this.seconds = 0;
                clearInterval(this.interval);
                this.$router.push("/onielplaylistwrapped2024"); // Redirect to Launch Page
                return;
            }

            this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
            this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
        },
        togglePlay() {
            const audio = this.$refs.audio;
            if (this.isPlaying) {
                audio.pause();
            } else {
                audio.play();
                if (this.isMuted) {
                    audio.muted = false; // Unmute saat play
                    this.isMuted = false;
                }
            }
            this.isPlaying = !this.isPlaying;
        },
        toggleMute() {
            const audio = this.$refs.audio;
            audio.muted = !audio.muted;
            this.isMuted = audio.muted;
        },
    },
    mounted() {
        this.updateCountdown();
        this.interval = setInterval(this.updateCountdown, 1000);

        // Set default muted state
        const audio = this.$refs.audio;
        audio.muted = this.isMuted;
    },
    beforeUnmount() {
        clearInterval(this.interval);
    },
};
</script>