import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import BotOniel from '../views/BotOniel.vue';
import WhatSongOnielPlay from '../views/WSOP.vue';
import BotOnielVid from '../views/BotOnielVid.vue';
import FindSong from '../views/WSOPFindSong.vue';
import OnielandRecap from '../views/Onieland.vue';
import SSK from '../views/Sousenkyo.vue';
import BotOnielCari from '../views/BotOnielCari.vue';
import BotOnielQuote from '../views/BotOnielQuote.vue';
import WaitingRoom from '../views/WaitingRoom.vue';
import OnielWrapped from '../views/Testing.vue';
import NotFound from '../views/ErrorNotFound.vue';

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			title: "Special for Cornelia",
		},
	},
	{
		path: '/botoniel',
		name: 'BotOniel',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: BotOniel,
		meta: {
			title: 'BotOniel Jokes Generator',
		},
	},
	{
		path: '/what-song-oniel-play',
		name: 'What Song Oniel Play?',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: WhatSongOnielPlay,
		meta: {
			title: 'Dashboard: What Song Oniel Play?',
		},
	},
	{
		path: '/botoniel/video',
		name: 'BotOnielVid',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: BotOnielVid,
		meta: {
			title: 'BotOniel Jokes Generator: Video',
		},
	},
	{
		path: '/what-song-oniel-play/cari',
		name: 'FindSong',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: FindSong,
		meta: {
			title: 'List #WhatSongOnielPlay',
		},
	},
	{
		path: '/onieland-recap',
		name: 'OnielandRecap',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: OnielandRecap,
		meta: {
			title: 'Recap #OnieLand',
		},
	},
	{
		path: '/general-election',
		name: 'SSK 2024 Campaign',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: SSK,
		meta: {
			title: '#SuksesOnielUntukSousenkyo',
		},
	},
	{
		path: '/wsop-list',
		name: 'WSOPList',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: FindSong,
		meta: {
			title: 'List "What Song Oniel Play?"',
		},
	},
	{
		path: '/wsop-stats',
		name: 'WSOP',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: WhatSongOnielPlay,
		meta: {
			title: 'Stats: "What Song Oniel Play?"',
		},
	},
	{
		path: '/botoniel/cari',
		name: 'BotOnielCari',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: BotOnielCari,
		meta: {
			title: 'BotOniel: Cari Jokes Oniel',
		},
	},
	{
		path: '/botoniel/quote',
		name: 'BotOnielQuote',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: BotOnielQuote,
		meta: {
			title: 'BotOniel: Cari Quote Oniel',
		},
	},
	{
		path: '/waitingroom',
		name: 'Stay Tuned, #OnielPlaylistWrapped2025!',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: WaitingRoom,
		meta: {
			title: 'Stay Tuned!',
		},
	},
	{
		path: '/onielplaylistwrapped2024',
		name: 'Oniel Playlist Wrapped',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: OnielWrapped,
		beforeEnter: (to, from, next) => {
			const targetDate = new Date("2025-12-31T23:59:00+07:00").getTime();
			const now = new Date().toLocaleString("en-US", { timeZone: "Asia/Jakarta" });
			const currentTime = new Date(now).getTime();

			if (currentTime >= targetDate) {
				next(); // Izinkan akses jika waktu sudah melewati targetDate
			} else {
				next("/waitingroom"); // Redirect kembali ke WaitingRoom jika belum waktunya
			}
		},
		meta: {
			title: 'See You on 2025!',
		},
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'Error Not Found',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: NotFound,
		meta: {
			title: 'Error: Not Found',
		},
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior() {
		document.getElementById('app').scrollIntoView();
	},
});

export default router;

/**
 * Below code will display the component/active page title
 * Powered by: Nangialai Stoman
 */

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
	// This goes through the matched routes from last to first, finding the closest route with a title.
	// e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
	// `/nested`'s will be chosen.
	const nearestWithTitle = to.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.title);

	// Find the nearest route element with meta tags.
	const nearestWithMeta = to.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.metaTags);

	const previousNearestWithMeta = from.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.metaTags);

	// If a route with a title was found, set the document (page) title to that value.
	if (nearestWithTitle) {
		document.title = nearestWithTitle.meta.title;
	} else if (previousNearestWithMeta) {
		document.title = previousNearestWithMeta.meta.title;
	}

	// Remove any stale meta tags from the document using the key attribute we set below.
	Array.from(
		document.querySelectorAll('[data-vue-router-controlled]')
	).map((el) => el.parentNode.removeChild(el));

	// Skip rendering meta tags if there are none.
	if (!nearestWithMeta) return next();

	// Turn the meta tag definitions into actual elements in the head.
	nearestWithMeta.meta.metaTags
		.map((tagDef) => {
			const tag = document.createElement('meta');

			Object.keys(tagDef).forEach((key) => {
				tag.setAttribute(key, tagDef[key]);
			});

			// We use this to track which meta tags we create so we don't interfere with other ones.
			tag.setAttribute('data-vue-router-controlled', '');

			return tag;
		})
		// Add the meta tags to the document head.
		.forEach((tag) => document.head.appendChild(tag));

	if (to.meta.title) {
		document.title = to.meta.title;
	}

	next();
});
