<template>
    <div class="flex items-center justify-center h-screen bg-gray-900 text-white">
        <div class="text-center">
            <h1 class="text-4xl font-bold mb-4">Not Found</h1>
            <p class="text-lg mb-6">looking for something?</p>
            <router-link to="/"
          class="flex justify-center items-center w-48 mt-12 mb-6 sm:mb-0 text-lg border border-indigo-200 py-2.5 sm:py-3 shadow-lg rounded-lg bg-indigo-50 focus:ring-1 focus:ring-indigo-900 hover:bg-sky-500 text-gray-500 hover:text-white duration-500">
          <i data-feather="home" class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6 duration-100"></i>
          <span class="text-sm sm:text-lg font-general-medium duration-100">Back to Home</span>
        </router-link>
        </div>
    </div>
    <AppFooter />
</template>

<script>
import AppFooter from '@/components/shared/AppFooterNonHome.vue';

export default {
    name: 'Stay Tuned',
    components: {
        AppFooter,
    },
};
</script>